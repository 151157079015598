import UrlParser from "../../routes/url-parser";
import LandingDbSource from "../../data/landingdb-source";
import API_ENDPOINT from "../../globals/api-endpoint";
import {
  ProfileDetail,
  ActivityDetail,
  mediaPartnerDetail,
  eventDetail,
  orderDetail,
} from "../component/detail/detail";

const detailProfile = {
  async render() {
    return `
    <div class="content textPutih " id="content" tabindex="0"></div>
    `;
  },

  async afterRender() {
    document.title = "DETAIL PROFILE";
    // Fungsi ini akan dipanggil setelah render()
    const url = UrlParser.parseActiveUrlWithoutCombiner();

    try {
      const profileData = await LandingDbSource.detailProfile(url.id);

      const profileContainer = document.querySelector("#content");
      profileContainer.innerHTML = ProfileDetail(profileData.profile);
    } catch (error) {}
  },
};

const detailActivity = {
  async render() {
    return `
    <div class="content textPutih " id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "DETAIL ACTIVITY";
    const url = UrlParser.parseActiveUrlWithoutCombiner();

    // Fungsi ini akan dipanggil setelah render()
    try {
      const activityData = await LandingDbSource.detailActivity(url.id);
      // console.log(activityData);
      const activityContainer = document.querySelector("#content");
      activityContainer.innerHTML = ActivityDetail(activityData.activity);
    } catch (error) {}
  },
};

const detailMediaPartner = {
  async render() {
    return `
    <div class="content textPutih " id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "DETAIL MEDIA PARTNER";
    const url = UrlParser.parseActiveUrlWithoutCombiner();

    // Fungsi ini akan dipanggil setelah render()
    try {
      const mediaPartnerData = await LandingDbSource.detailMediaPartner(url.id);
      console.log(mediaPartnerData);
      const mediaPartnerContainer = document.querySelector("#content");
      mediaPartnerContainer.innerHTML = mediaPartnerDetail(
        mediaPartnerData.mediaPartner
      );
    } catch (error) {}
  },
};

// const detailEvent = {
//   async render() {
//     return `
//     <div class="content textPutih " id="content" tabindex="0"></div>
//     `;
//   },
//   async afterRender() {
//     document.title = "DETAIL EVENT";
//     const url = UrlParser.parseActiveUrlWithoutCombiner();

//     // Fungsi ini akan dipanggil setelah render()
//     try {
//       const eventData = await LandingDbSource.detailEvent(url.id);
//       console.log(eventData.event.id);
//       const eventContainer = document.querySelector("#content");
//       eventContainer.innerHTML = eventDetail(eventData.event);
//     } catch (error) {}
//   },
// };

const detailEvent = {
  async render() {
    return `
    <div class="content textPutih " id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "DETAIL EVENT";
    const url = UrlParser.parseActiveUrlWithoutCombiner();

    try {
      const eventData = await LandingDbSource.detailEvent(url.id);
      const paymentData = await LandingDbSource.AllPayment();
      console.log("paymentData", paymentData);
      const eventContainer = document.querySelector("#content");
      eventContainer.innerHTML = eventDetail(eventData.event, paymentData);

      // Mengatur event listener untuk tombol quantity
      const ticketQuantityInput = document.getElementById("ticketQuantity");
      const increaseButton = document.getElementById("increaseQuantity");
      const decreaseButton = document.getElementById("decreaseQuantity");

      // Tombol Tambah
      increaseButton.addEventListener("click", () => {
        const currentValue = parseInt(ticketQuantityInput.value, 10);
        const maxValue = parseInt(ticketQuantityInput.max, 10);

        if (currentValue < maxValue) {
          ticketQuantityInput.value = currentValue + 1;
        } else {
          Swal.fire({
            icon: "warning",
            title: "Jumlah Maksimum Tercapai",
            text: `Anda hanya dapat membeli maksimal ${maxValue} tiket.`,
            confirmButtonColor: "#ffc107",
          });
        }
      });

      // Tombol Kurang
      decreaseButton.addEventListener("click", () => {
        const currentValue = parseInt(ticketQuantityInput.value, 10);
        if (currentValue > parseInt(ticketQuantityInput.min, 10)) {
          ticketQuantityInput.value = currentValue - 1;
        } else {
          Swal.fire({
            icon: "warning",
            title: "Jumlah Minimal Tercapai",
            text: `Anda harus membeli minimal 1 tiket.`,
            confirmButtonColor: "#ffc107",
          });
        }
      });

      const buyTicketButton = document.getElementById("buyTicketButton");
      const buyTicketModal = document.getElementById("buyTicketModal");
      const closeModalButtons = [
        document.getElementById("closeModalButton"),
        document.getElementById("closeModalFooterButton"),
      ];

      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData) {
        document.getElementById("customerName").value =
          userData.profile.fullName;

        document.getElementById("email").value = userData.email;

        document.getElementById("phoneNumber").value =
          userData.profile.phoneNumber;

        const phoneNumberInput = document.getElementById("phoneNumber");
        const countryCodeSelect = document.getElementById("countryCode");

        // Isi nomor telepon dengan data dari localStorage jika tersedia
        phoneNumberInput.value = userData.profile.phoneNumber || "";

        // Event listener untuk perubahan pada dropdown kode negara
        countryCodeSelect.addEventListener("change", () => {
          console.log(
            `Kode Negara: ${countryCodeSelect.value}, Nomor Telepon: ${phoneNumberInput.value}`
          );
        });
      }

      buyTicketButton.addEventListener("click", () => {
        buyTicketModal.style.display = "block";
      });

      closeModalButtons.forEach((button) =>
        button.addEventListener("click", () => {
          buyTicketModal.style.display = "none";
        })
      );

      // Menambahkan event listener untuk form submit
      const ticketForm = document.getElementById("ticketForm");
      ticketForm.addEventListener("submit", async (event) => {
        event.preventDefault(); // Mencegah form melakukan submit secara default
        buyTicketModal.style.display = "none";

        const customerName = document.getElementById("customerName").value;
        const email = document.getElementById("email").value;
        const phoneNumber = document.getElementById("phoneNumber").value;
        const countryCode = document.getElementById("countryCode").value;
        const paymentMethod = document.getElementById("paymentMethod").value;
        const ticketQuantity = parseInt(ticketQuantityInput.value, 10);

        const orderData = {
          customerName,
          email,
          phoneNumber: `${countryCode}${phoneNumber}`,
          paymentMethod,
          ticketQuantity,
          eventId: eventData.event.id,

          userId: `${userData.id}`,
          eventDetails: [
            {
              eventId: `${url.id}`,
              quantity: ticketQuantity,
            },
          ],
          customerName: customerName,
          email: email,
          phoneNumber: `${countryCode}${phoneNumber}`,
          paymentMethodId: paymentMethod,
        };

        console.log("Data yang akan dikirim:", orderData);

        try {
          const response = await fetch(`${API_ENDPOINT.ORDER}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(orderData),
          });

          if (response.status === 201) {
            const responseData = await response.json();
            console.log("Pembelian Tiket Berhasil", responseData);
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Pembelian tiket berhasil.",
            })
            // Tampilkan notifikasi atau redirect sesuai kebutuhan
            window.location.hash = "#/user/transaksi";
          } else {
            console.error("Gagal mengirim data", await response.text());
            Swal.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: "Gagal melakukan pembelian tiket.",
            });
          }
        } catch (error) {
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: "Gagal melakukan pembelian tiket.",
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  },
};

const detailOder = {
  async render() {
    return `
    <div class="content textPutih content-order-detail" id="content" tabindex="0"></div>
    `;
  },
  async afterRender() {
    document.title = "DETAIL ORDER";
    const url = UrlParser.parseActiveUrlWithoutCombiner();

    try {
      const orderData = await LandingDbSource.detailOrder(url.id);

      const orderContainer = document.querySelector(".content-order-detail");
      orderContainer.innerHTML = orderDetail(orderData);
    } catch (error) {}
  },
};

export {
  detailProfile,
  detailActivity,
  detailMediaPartner,
  detailEvent,
  detailOder,
};
