import API_ENDPOINT from "../../globals/api-endpoint";

import {
  dashboardUserPage,
  dashboardAdminPage,
  dashboardSidebar,
} from "../component/users/dashboard-creator";

const Dashboard = {
  async render() {
    return `
      <div class="content" tabindex="0">
        <section id="content-dashboard" class="content-dashboard"></section>
      </div>
    `;
  },

  async afterRender() {
    try {
      const contentContainer = document.querySelector("#content-dashboard");
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        // Tampilkan halaman sesuai peran
        if (user.role === "admin") {
          contentContainer.innerHTML = dashboardAdminPage(); // Admin Dashboard

          // Load data admin
          await this.loadAdminData();
        } else if (user.role === "user") {
          contentContainer.innerHTML = dashboardUserPage(); // User Dashboard
        }

        // Tampilkan sidebar sesuai peran
        const sidebarContainer = document.querySelector("#sidebar-container");
        sidebarContainer.innerHTML = dashboardSidebar(user.role);

        // Tambahkan event listener untuk tombol Logout
        const logoutBtn = document.getElementById("logoutBtn");
        if (logoutBtn) {
          logoutBtn.addEventListener("click", async () => {
            // Tampilkan dialog konfirmasi dengan SweetAlert2
            const result = await Swal.fire({
              title: "Konfirmasi Logout",
              text: "Apakah Anda yakin ingin logout?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ya, logout!",
              cancelButtonText: "Batal",
            });

            if (result.isConfirmed) {
              // Hapus data dari localStorage
              localStorage.clear();
              await Swal.fire(
                "Logout Berhasil",
                "Anda telah logout.",
                "success"
              );
              window.location.href = "#/login"; // Arahkan ke halaman login
              window.location.reload();
            }
          });
        }
      } else {
        // Tampilkan pemberitahuan jika user tidak ditemukan
        Swal.fire({
          icon: "error",
          title: "User Tidak Ditemukan",
          text: "Silakan login kembali.",
        }).then(() => {
          window.location.href = "#/login"; // Arahkan ke login
        });
      }
    } catch (error) {
      console.error("Error rendering dashboard:", error);

      // Tampilkan error pada UI
      Swal.fire({
        icon: "error",
        title: "Kesalahan",
        text: "Terjadi kesalahan saat memuat dashboard.",
      });
    }
  },

  async loadAdminData() {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(API_ENDPOINT.GET_ALL_USERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch users");

      const data = await response.json();

      // Filter pengguna dan hitung jumlahnya
      const userCount = data.count - 1;

      // Update kotak total pengguna
      const userCountBox = document.getElementById("user-count");
      if (userCountBox) userCountBox.textContent = userCount;
    } catch (error) {
      console.error("Error loading admin data:", error);

      // Tampilkan error pada UI dan notifikasi SweetAlert2
      const userCountBox = document.getElementById("user-count");
      if (userCountBox) userCountBox.textContent = "Error";

      Swal.fire({
        icon: "error",
        title: "Gagal Memuat Data",
        text: "Tidak dapat mengambil data pengguna.",
      });
    }
  },
};

export default Dashboard;
